<template>
  <div class="sort-box">
    <van-tree-select
      height="100vh"
      :items="items"
      :main-active-index.sync="active"
    >
      <template #content>
        <div v-for="(item, index) of items" :key="index">
          <div v-show="active === index">
            <van-grid :column-num="2" :gutter="10">
              <van-grid-item v-for="(sItem, sIndex) in item.list" :key="sIndex">
                 <div class="name" v-html="sItem.name" @click="onOpen(sItem)" />
              </van-grid-item>
            </van-grid>
          </div>
        </div>
      </template>
    </van-tree-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      items: [
        {
          text: "小蓝帽",
          list: [
            {
              name: "设备箱材质",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%881%EF%BC%89%E8%AE%BE%E5%A4%87%E7%AE%B1%E6%9D%90%E8%B4%A8.mp4",
            },
            {
              name: "防锈处理",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%882%EF%BC%89%E9%98%B2%E9%94%88%E5%A4%84%E7%90%86.mp4",
            },
            {
              name: "涂装",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%883%EF%BC%89%E6%B6%82%E8%A3%85.mp4",
            },
            {
              name: "箱门门锁",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%884%EF%BC%89%E7%AE%B1%E9%97%A8%E9%97%A8%E9%94%81.mp4",
            },
            {
              name: "箱体通风",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%885%EF%BC%89%E7%AE%B1%E4%BD%93%E9%80%9A%E9%A3%8E.mp4",
            },
            {
              name: "施工进度管理",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%886%EF%BC%89%E6%96%BD%E5%B7%A5%E8%BF%9B%E5%BA%A6%E7%AE%A1%E7%90%86.mp4",
            },
            {
              name: "施工进度可控<br/>可管",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%887%EF%BC%89%E6%96%BD%E5%B7%A5%E8%BF%9B%E5%BA%A6%E5%8F%AF%E6%8E%A7%E5%8F%AF%E7%AE%A1.mp4",
            },
            {
              name: "内箱二维码",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%888%EF%BC%89%E5%86%85%E7%AE%B1%E4%BA%8C%E7%BB%B4%E7%A0%81.mp4",
            },
            {
              name: "防雷器",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%889%EF%BC%89%E9%98%B2%E9%9B%B7%E5%99%A8.mp4",
            },
            {
              name: "空开",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%8810%EF%BC%89%E7%A9%BA%E5%BC%80.mp4",
            },
            {
              name: "自动重合闸",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%8811%EF%BC%89%E8%87%AA%E5%8A%A8%E9%87%8D%E5%90%88%E9%97%B8.mp4",
            },
            {
              name: "电源插座",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%8812%EF%BC%89%E7%94%B5%E6%BA%90%E6%8F%92%E5%BA%A7.mp4",
            },
            {
              name: "检测终端",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%8813%EF%BC%89%E6%A3%80%E6%B5%8B%E7%BB%88%E7%AB%AF.mp4",
            },
            {
              name: "工业交换机",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%8814%EF%BC%89%E5%B7%A5%E4%B8%9A%E4%BA%A4%E6%8D%A2%E6%9C%BA.mp4",
            },
            {
              name: "开关电源",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%8815%EF%BC%89%E5%BC%80%E5%85%B3%E7%94%B5%E6%BA%90.mp4",
            },
            {
              name: "维修便捷",
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%8816%EF%BC%89%E7%BB%B4%E4%BF%AE%E4%BE%BF%E6%8D%B7.mp4",
            },
            {
              name: '最能“装”',
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%8817%EF%BC%89%E6%9C%80%E8%83%BD%E2%80%9C%E8%A3%85%E2%80%9D.mp4",
            },
            {
              name: '预留光纤位置',
              video: "https://sws.peppa.zjsws.net/%E8%88%8D%E4%B8%BA%E6%80%9D%E2%80%9C%E5%B0%8F%E8%93%9D%E5%B8%BD%E2%80%9D%E8%AE%BE%E5%A4%87%E7%AE%B1%EF%BC%8818%EF%BC%89%E9%A2%84%E7%95%99%E5%85%89%E7%BA%A4%E4%BD%8D%E7%BD%AE.mp4",
            },
          ],
        },
        {
          text: "应急配电柜",
          list: [
            {
              name: "应急配电柜<br/>(一)",
              video: "https://sws.peppa.zjsws.net/%E4%BA%A4%E8%AD%A6%E4%BF%A1%E5%8F%B7%E7%81%AF%E5%BA%94%E6%80%A5%E9%85%8D%E7%94%B5%E6%9F%9C%EF%BC%88%E4%B8%80%EF%BC%89.mp4",
            },
            {
              name: "应急配电柜<br/>(二)",
              video: "https://sws.peppa.zjsws.net/%E4%BA%A4%E8%AD%A6%E4%BF%A1%E5%8F%B7%E7%81%AF%E5%BA%94%E6%80%A5%E9%85%8D%E7%94%B5%E6%9F%9C%EF%BC%88%E4%BA%8C%EF%BC%89.mp4",
            },
            {
              name: "应急配电柜<br/>(三)",
              video: "https://sws.peppa.zjsws.net/%E4%BA%A4%E8%AD%A6%E4%BF%A1%E5%8F%B7%E7%81%AF%E5%BA%94%E6%80%A5%E9%85%8D%E7%94%B5%E6%9F%9C%EF%BC%88%E4%B8%89%29.mp4",
            },
            {
              name: "应急配电柜<br/>(四)",
              video: "https://sws.peppa.zjsws.net/%E4%BA%A4%E8%AD%A6%E4%BF%A1%E5%8F%B7%E7%81%AF%E5%BA%94%E6%80%A5%E9%85%8D%E7%94%B5%E6%9F%9C%EF%BC%88%E5%9B%9B%EF%BC%89.mp4",
            },
          ]
        },
        {
          text: "工具使用",
          list: [
            {
              name: "多功能网络测线仪1",
              video: "https://sws.peppa.zjsws.net/%E5%A4%9A%E5%8A%9F%E8%83%BD%E7%BD%91%E7%BB%9C%E6%B5%8B%E7%BA%BF%E4%BB%AA1.mp4",
            },
            {
              name: "多功能网络测线仪2",
              video: "https://sws.peppa.zjsws.net/%E5%A4%9A%E5%8A%9F%E8%83%BD%E7%BD%91%E7%BB%9C%E6%B5%8B%E7%BA%BF%E4%BB%AA2.mp4",
            },
            {
              name: "多功能网络测线仪3",
              video: "https://sws.peppa.zjsws.net/%E5%A4%9A%E5%8A%9F%E8%83%BD%E7%BD%91%E7%BB%9C%E6%B5%8B%E7%BA%BF%E4%BB%AA3.mp4",
            },
            {
              name: "多功能网络测线仪4",
              video: "https://sws.peppa.zjsws.net/%E5%A4%9A%E5%8A%9F%E8%83%BD%E7%BD%91%E7%BB%9C%E6%B5%8B%E7%BA%BF%E4%BB%AA4.mp4",
            },
            {
              name: "多功能网络测线仪5",
              video: "https://sws.peppa.zjsws.net/%E5%A4%9A%E5%8A%9F%E8%83%BD%E7%BD%91%E7%BB%9C%E6%B5%8B%E7%BA%BF%E4%BB%AA5.mp4",
            },
            {
              name: "多功能网络测线仪6",
              video: "https://sws.peppa.zjsws.net/%E5%A4%9A%E5%8A%9F%E8%83%BD%E7%BD%91%E7%BB%9C%E6%B5%8B%E7%BA%BF%E4%BB%AA6.mp4",
            },
            {
              name: "多功能网络测线仪7",
              video: "https://sws.peppa.zjsws.net/%E5%A4%9A%E5%8A%9F%E8%83%BD%E7%BD%91%E7%BB%9C%E6%B5%8B%E7%BA%BF%E4%BB%AA7.mp4",
            },
            {
              name: "工程宝1",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D1.mov",
            },
            {
              name: "工程宝2",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D2.mov",
            },
            {
              name: "工程宝3",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D3.mp4",
            },
            {
              name: "工程宝4",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D4.mp4",
            },
            {
              name: "工程宝5",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D5.mp4",
            },
            {
              name: "工程宝6",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D6.mp4",
            },
            {
              name: "工程宝7",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D7.mp4",
            },
            {
              name: "工程宝8",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D8.mp4",
            },
            {
              name: "工程宝9",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D9.mov",
            },
            {
              name: "工程宝10",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D10.mp4",
            },
            {
              name: "工程宝11",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D11.mp4",
            },
            {
              name: "工程宝12",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D12.mov",
            },
            {
              name: "工程宝13",
              video: "https://sws.peppa.zjsws.net/%E5%B7%A5%E7%A8%8B%E5%AE%9D13.mov",
            },
            {
              name: "并线器使用方法",
              video: "https://sws.peppa.zjsws.net/%E5%B9%B6%E7%BA%BF%E5%99%A8%E4%BD%BF%E7%94%A8%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "光功率计的使用方法",
              video: "https://sws.peppa.zjsws.net/%E5%85%89%E5%8A%9F%E7%8E%87%E8%AE%A1%E7%9A%84%E4%BD%BF%E7%94%A8%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "光纤切割刀使用方法",
              video: "https://sws.peppa.zjsws.net/%E5%85%89%E7%BA%A4%E5%88%87%E5%89%B2%E5%88%80%E4%BD%BF%E7%94%A8%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "光纤与光纤熔接",
              video: "https://sws.peppa.zjsws.net/%E5%85%89%E7%BA%A4%E4%B8%8E%E5%85%89%E7%BA%A4%E7%86%94%E6%8E%A5.mov",
            },
            {
              name: "红光笔的使用方法",
              video: "https://sws.peppa.zjsws.net/%E7%BA%A2%E5%85%89%E7%AC%94%E7%9A%84%E4%BD%BF%E7%94%A8%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "胶布正确缠绕方法",
              video: "https://sws.peppa.zjsws.net/%E8%83%B6%E5%B8%83%E6%AD%A3%E7%A1%AE%E7%BC%A0%E7%BB%95%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "米勒钳的使用方法",
              video: "https://sws.peppa.zjsws.net/%E7%B1%B3%E5%8B%92%E9%92%B3%E7%9A%84%E4%BD%BF%E7%94%A8%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "熔纤机主要部件名称",
              video: "https://sws.peppa.zjsws.net/%E7%86%94%E7%BA%A4%E6%9C%BA%E4%B8%BB%E8%A6%81%E9%83%A8%E4%BB%B6%E5%90%8D%E7%A7%B0.mp4",
            },
            {
              name: "万用表常用测量方法",
              video: "https://sws.peppa.zjsws.net/%E4%B8%87%E7%94%A8%E8%A1%A8%E5%B8%B8%E7%94%A8%E6%B5%8B%E9%87%8F%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "网络测线仪的使用方法",
              video: "https://sws.peppa.zjsws.net/%E7%BD%91%E7%BB%9C%E6%B5%8B%E7%BA%BF%E4%BB%AA%E7%9A%84%E4%BD%BF%E7%94%A8%E6%96%B9%E6%B3%95.mp4",
            }
          ]
        },
        {
          text: "接线方法",
          list: [
            {
              name: "不同截面单股铜导线",
              video: "https://sws.peppa.zjsws.net/%E4%B8%8D%E5%90%8C%E6%88%AA%E9%9D%A2%E5%8D%95%E8%82%A1%E9%93%9C%E5%AF%BC%E7%BA%BF.mp4",
            },
            {
              name: "大截面单股铜导线",
              video: "https://sws.peppa.zjsws.net/%E5%A4%A7%E6%88%AA%E9%9D%A2%E5%8D%95%E8%82%A1%E9%93%9C%E5%AF%BC%E7%BA%BF.mp4",
            },
            {
              name: "单股铜导线T字分支",
              video: "https://sws.peppa.zjsws.net/%E5%8D%95%E8%82%A1%E9%93%9C%E5%AF%BC%E7%BA%BFT%E5%AD%97%E5%88%86%E6%94%AF.mp4",
            },
            {
              name: "单股铜导线十字分支",
              video: "https://sws.peppa.zjsws.net/%E5%8D%95%E8%82%A1%E9%93%9C%E5%AF%BC%E7%BA%BF%E5%8D%81%E5%AD%97%E5%88%86%E6%94%AF.mp4",
            },
            {
              name: "单股与多股铜导线",
              video: "https://sws.peppa.zjsws.net/%E5%8D%95%E8%82%A1%E4%B8%8E%E5%A4%9A%E8%82%A1%E9%93%9C%E5%AF%BC%E7%BA%BF.mp4",
            },
            {
              name: "多股铜导线分支连接",
              video: "https://sws.peppa.zjsws.net/%E5%A4%9A%E8%82%A1%E9%93%9C%E5%AF%BC%E7%BA%BF%E5%88%86%E6%94%AF%E8%BF%9E%E6%8E%A5.mp4",
            },
            {
              name: "光纤冷接子接头制作",
              video: "https://sws.peppa.zjsws.net/%E5%85%89%E7%BA%A4%E5%86%B7%E6%8E%A5%E5%AD%90%E6%8E%A5%E5%A4%B4%E5%88%B6%E4%BD%9C.mp4",
            },
            {
              name: "接线端子排连接方法",
              video: "https://sws.peppa.zjsws.net/%E6%8E%A5%E7%BA%BF%E7%AB%AF%E5%AD%90%E6%8E%92%E8%BF%9E%E6%8E%A5%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "如何正确连接防雷器",
              video: "https://sws.peppa.zjsws.net/%E5%A6%82%E4%BD%95%E6%AD%A3%E7%A1%AE%E8%BF%9E%E6%8E%A5%E9%98%B2%E9%9B%B7%E5%99%A8.mp4",
            },
            {
              name: "同一方向导线连接方法",
              video: "https://sws.peppa.zjsws.net/%E5%90%8C%E4%B8%80%E6%96%B9%E5%90%91%E5%AF%BC%E7%BA%BF%E8%BF%9E%E6%8E%A5%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "网络模块的压制方法",
              video: "https://sws.peppa.zjsws.net/%E7%BD%91%E7%BB%9C%E6%A8%A1%E5%9D%97%E7%9A%84%E5%8E%8B%E5%88%B6%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "网络摄像机IP配置方法",
              video: "https://sws.peppa.zjsws.net/%E7%BD%91%E7%BB%9C%E6%91%84%E5%83%8F%E6%9C%BAIP%E9%85%8D%E7%BD%AE%E6%96%B9%E6%B3%95.mp4",
            },
            {
              name: "网络跳线的制作",
              video: "https://sws.peppa.zjsws.net/%E7%BD%91%E7%BB%9C%E8%B7%B3%E7%BA%BF%E7%9A%84%E5%88%B6%E4%BD%9C.mp4",
            },
            {
              name: "小截面单股铜导线连接",
              video: "https://sws.peppa.zjsws.net/%E5%B0%8F%E6%88%AA%E9%9D%A2%E5%8D%95%E8%82%A1%E9%93%9C%E5%AF%BC%E7%BA%BF%E8%BF%9E%E6%8E%A5.mp4",
            }
          ]
        },
        {
          text: "故障排查",
          list: [
            {
              name: "箱内设备介绍",
              video: "https://sws.peppa.zjsws.net/1%20%E7%AE%B1%E5%86%85%E8%AE%BE%E5%A4%87%E4%BB%8B%E7%BB%8D%EF%BC%88%E6%8B%86%E8%A7%A3%E7%89%88%EF%BC%89.mp4",
            },
            {
              name: "监控无图像电脑端排查",
              video: "https://sws.peppa.zjsws.net/2%20%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E7%94%B5%E8%84%91%E7%AB%AF%E6%8E%92%E6%9F%A5.mp4",
            },
            {
              name: "排查空开和进电",
              video: "https://sws.peppa.zjsws.net/3%20%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E7%A9%BA%E5%BC%80%E5%92%8C%E8%BF%9B%E7%94%B5.mp4",
            },
            {
              name: "排查摄像机电源",
              video: "https://sws.peppa.zjsws.net/4%20%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E6%91%84%E5%83%8F%E6%9C%BA%E7%94%B5%E6%BA%90.mp4",
            },
            {
              name: "排查光纤收发器",
              video: "https://sws.peppa.zjsws.net/5%20%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E5%85%89%E7%BA%A4%E6%94%B6%E5%8F%91%E5%99%A8.mp4",
            },
            {
              name: "主光纤排查",
              video: "https://sws.peppa.zjsws.net/6%20%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E4%B8%BB%E5%85%89%E7%BA%A4%E6%8E%92%E6%9F%A5.mp4",
            },
            {
              name: "排查防雷器",
              video: "https://sws.peppa.zjsws.net/7%20%E7%9B%91%E6%8E%A7%E6%97%A0%E5%9B%BE%E5%83%8F%E6%8E%92%E6%9F%A5%E9%98%B2%E9%9B%B7%E5%99%A8.mp4",
            },
            {
              name: "排查浪涌保护器",
              video: "https://sws.peppa.zjsws.net/8%20%E6%8E%92%E6%9F%A5%E6%B5%AA%E6%B6%8C%E4%BF%9D%E6%8A%A4%E5%99%A8.mp4",
            }
          ]
        }
      ],
    };
  },
  methods: {
    onOpen(item) {
      window.open(item.video);
    },
  }
};
</script>

<style lang="less" scoped>
.sort-box {
  min-height: 100vh;
  ::v-deep .van-sidebar-item--select::before {
    background-color: #409EFF;
  }
  ::v-deep .van-tree-select__content {
    padding: 16px 0;
    flex: 2.8;
  }
  ::v-deep .van-grid-item__content {
    padding: 0;
  }
  .name {
    width: 100%;
    height: 80px;
    background: #F7FFFF;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center;
    box-sizing: border-box;
    padding: 0 12px;
    text-align: center;
    line-height: 20px;
  }
}
</style>